.card {
  position: relative;
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
}

.card--noimage {
  background: white;
  padding: 10px;
}

.card__figure {
  margin: 0 0 0.75rem;
  aspect-ratio: 1544 / 586; /* Aspect ratio of topimage */
}

.card--featured .card__figure {
  aspect-ratio: 989 / 450; /* From Lex sketches (taller than topimage) */
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}

.card__title {
  font: var(--front-large-link);
  margin-bottom: 0.5rem;
  text-wrap: balance;
}

.card__link {
  border-bottom-style: none;
}

.card__link:hover {
  border-bottom-style: solid;
}

.card__link::after {
  content: "";
  position: absolute;
  inset: 0;
}

.card__preamble {
  font: var(--front-preamble);
  margin-bottom: 0;
}

@media (--medium-up) {
  .card--noimage {
    padding: 20px;
  }

  .card__figure {
    margin-bottom: 1rem;
  }
}
