.taxonomy-overview__heading {
  font: var(--front-subheading);
  margin-bottom: 0.75rem;
}

.taxonomy-overview__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.taxonomy-overview__item {
  font: var(--home-link-font);
}

.taxonomy-overview__link {
  border-bottom-style: none;
  padding: 10px;
  padding-right: 44px;
  background: url('../images/arrow-right.svg') right 10px center no-repeat var(--color-periwinkle);
  display: block;
  max-width: max-content;
}

.taxonomy-overview__link:hover,
.taxonomy-overview__link:focus {
  text-decoration: underline;
}

@media (--medium-up) {
  .taxonomy-overview__heading {
    margin-bottom: 30px;
  }

  .taxonomy-overview__list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (--large-up) {
  .taxonomy-overview__link {
    padding-right: 79px;
    padding-bottom: 44px;
    background-position: left 10px bottom 10px;
  }
}
