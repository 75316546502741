.card-list__header {
  margin-bottom: 0.75rem;
}

.card-list__cards {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--gutter);
}

@media (--medium-up) {
  .card-list__header {
    padding-inline: var(--half-gutter);
  }

  .card-list__cards {
    padding-inline: var(--half-gutter);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--large-up) {
  .card-list__header {
    margin-bottom: 30px;
  }

  .card-list__cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .card-list__cards--four-col {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
