.l-landing-page__intro {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.l-landing-page__buttons {
  display: flex;
  gap: 1rem;
}

.l-landing-page__section {
  padding-block: 24px;
}

.l-landing-page__section--featured {
  background: white;
}

@media (--medium-up) {
  .l-landing-page__intro {
    margin-inline: var(--half-gutter);
  }
}

@media (--large-up) {
  .l-landing-page__intro {
    padding-top: 50px;
  }

  .l-landing-page__section {
    padding-block: 50px;
  }
}
