.lex-footer__main {
  padding-block: var(--gutter);
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
  font: var(--font-footer-details);
}

.lex-footer__logo {
  width: 182px;
  height: auto;
}

.lex-footer__intro {
  font: var(--font-footer-intro);
  margin-bottom: 10px;
}

.lex-footer__p {
  margin: 0;
}

.lex-footer__welcome {
  font: var(--font-footer-welcome);
}

.lex-footer__h2 {
  margin-bottom: 10px;
}

.lex-footer__address {
  font: inherit;
}

.lex-footer__link-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lex-footer__founders {
  background: var(--color-nobel);
  padding-block: 15px;
}

.lex-footer__founder-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.lex-footer__founder-link {
  border-bottom-style: none;
  display: inline-block;
}

.lex-footer__founder-logo {
  width: auto;
  height: 40px;
  vertical-align: top;
}

@media (--medium-up) {
  .lex-footer__main {
    display: grid;
    grid-template-columns:  repeat(2, minmax(0, 1fr));
  }

  .lex-footer__logo,
  .lex-footer__intro {
    grid-column: 1 / span 2;
  }
}

@media (--large-up) {
  .lex-footer__main {
    padding-block: 50px;
    grid-template-columns:  repeat(4, minmax(0, 1fr));
    row-gap: 90px;
  }

  .lex-footer__logo {
    width: 243px;
  }

  .lex-footer__intro {
    grid-column: 3 / span 2;
  }

  .lex-footer__founder-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: var(--gutter);
  }

  .lex-footer__founder-logo {
    height: 55px;
  }
}
