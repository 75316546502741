.card-list-header {
  display: flex;
}

.card-list-header__heading {
  flex: 1;
  font: var(--front-subheading);
}

.card-list-header__link:not(:hover) {
  border-bottom-color: transparent;
}

.card-list-header__link-text {
  font: var(--meta);
}

.card-list-header__icon {
  vertical-align: middle;
}

@media (--small) {
  .card-list-header__link-text {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
  }
}
